import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero-our-model"
import BehaviouralBusinessContent from "../../components/behavioural-business-content"


const BehaviouralBusinessPage = () => (
  <Layout>
    <SEO title="Behavioural Business" />
    <BehaviouralBusinessContent />
  </Layout>
)

export default BehaviouralBusinessPage